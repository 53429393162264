import axios from '@/plugins/axios';

class ChannelService {
  webhookMessenger(data) {
    this.data = data;
    return axios('v1').post('/webhook-page', this.data);
  }

  activeClient(data) {
    this.data = data;
    return axios('v1').post('/channels/whatsapp-business/active', this.data);
  }

  channels(id = null) {
    this.id = id;
    return axios('v1').get('/channels');
  }

  channelsPage(id = null) {
    this.id = id;
    return axios('v1').get(`/channels/page/${id}`);
  }

  instagramPage(id = null) {
    this.id = id;
    return axios('v1').get(`/channels/instagram/${id}`);
  }

  createTelegramBot(data) {
    this.data = data;
    return axios('v1').post('/channels/telegram/create-bot', this.data);
  }

  getTelegramBot(id = null) {
    this.id = id;
    return axios('v1').get('/channels/telegram/get-bots');
  }

  removeBot(id = null, channelId, channelIdLive) {
    this.id = id;
    this.channelId = channelId;
    this.channelIdLive = channelIdLive;
    return axios('v1').delete(`/channels/telegram/${id}/${channelId}/${channelIdLive}`);
  }

  channelsRemovePage(channel = null, id = null) {
    this.id = id;
    this.channel = channel;
    return axios('v1').delete(`/channels/page/${channel}/${id}`);
  }

  channelsRemoveInstagram(id = null) {
    this.id = id;
    return axios('v1').delete(`/channels/instagram/${id}`);
  }
}

export default new ChannelService();
